import { Link } from 'gatsby';
import React from 'react';

import Logo from './../images/icons/logo.svg';

const Header: React.FC<{ location: Location, lang: string }> = ({ location, lang }) => {

    return (
        <nav className="h-20 flex flex-wrap pb-2 content-end justify-center">
            <div className="flex-1"></div>
            <Link to={lang === 'default' ? '/' : '/' + lang + '/'} aria-label="Wadgassen">
                <Logo className="flex-1 h-8" alt="Wadgassen Logo" />
            </Link>
            {
                <div className="flex-1 flex flex-wrap justify-end content-center pr-6 text-sm">
                    <Link className={lang === "fr" ? "" : " text-blue-light-900 font-bold"} to={location.pathname.replace("/" + lang + "/", "/")}>
                        de
                      </Link>
                    <p className="px-2">|</p>
                    <Link className={lang === "default" ? "" : " text-blue-light-900 font-bold"} to={`/fr${location.pathname}`}>
                        fr
                      </Link>
                </div>
            }

        </nav>
    )
}

export default Header
