import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'

import BottomMenu from './BottomMenu';
import Header from './Header';

const title = "Wadgasser-Freizeit-App";
const description = "Wadgassen entdecken! Hier gibt es alle Infos zu unseren Freizeitangeboten, der örtlichen Gastronomie und anstehenden Veranstaltungen."

const Layout: React.FC<{ location: Location, pageContext: { lang: string } }> = ({ children, location, pageContext }) => {

    const isBrowser = typeof window !== "undefined"

    return (
        <div className="container max-w-screen-sm mx-auto bg-white">
            <Helmet
                titleTemplate="Wadgassen - %s"
                defaultTitle={title}>
                <html lang="de" />
                <meta name="description" content={description} />
                <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                {isBrowser && <meta property="og:url" content={window.location.href} />}
                <meta property="og:type" content="website" />ƒ
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://a.storyblok.com/f/104645/1200x628/002289f87f/wadgassenheaderimage.jpg" />
                <script async defer data-domain="wadgassen.app" src="https://plausible.io/js/plausible.js"></script>
            </Helmet>
            <Header location={location} lang={pageContext.lang} />
            <main className="mx-1 bg-grey grid overflow-x-hidden">
                {children}
            </main>
            {/* <BottomMenu /> */}
            <footer className="text-center text-xs p-4 flex justify-evenly" >
                <Link to="/impressum" >Impressum</Link>
                <Link to="/datenschutz">Datenschutz</Link>
            </footer>
        </div>
    )
}

export default Layout