module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wadgassen Freizeit App","short_name":"Wadgassen","icon":"src/images/icons/favicon.svg","background_color":"#ffffff","theme_color":"#ffffff","display":"fullscreen","icon_options":{"purpose":"any maskable"},"start_url":"/","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d94f76e94c7a13d2ca1902e811be346a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/wandern/*","/*"],"workboxConfig":{"globPatterns":["**/*.{js,jpg,png,html,css,svg}"]},"appendScript":"/opt/build/repo/range-request-handler.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
